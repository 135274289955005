const appConfig = {
  "name": "pfmla-web",
  "version": "1.0.0",
  "stage": "release",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-test.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_nY154N4yV",
        "userPoolWebClientId": "42o57otta49734jspnd7n9iq4g",
        "oauth": {
          "domain": "atlas-auth-test.mit.edu",
          "scope": [
            "profile",
            "openid",
            "digital-id/user",
            "pfmla/export"
          ],
          "redirectSignIn": "https://hrfeeds-test.mit.edu/",
          "redirectSignOut": "https://hrfeeds-test.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "hostname": "https://api-test.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1",
          "pfmla": "pfmla-export-v1"
        }
      }
    }
  ]
};
export default appConfig;